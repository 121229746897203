import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

const NotFoundPage = ({ data }) => {
  const pageProps = data?.prismic?.not_found;
  const { document_title, body } = pageProps;
  return (
    <div>
      <h1>{document_title}</h1>
      <RichText render={body} />
    </div>
  );
};
export default NotFoundPage;

export const query = graphql`
query {
  prismic {
    not_found(lang: "en-us", uid: "404") {
      title
      document_title
      body
    }
  }
}
`;